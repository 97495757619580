var navigation = (function () {

    var DOM = {

        html: document.documentElement,
        nav: document.getElementsByClassName('navigation')[0],
        navToggle: document.getElementById('topmenu__button'),
        navOverlay: document.getElementsByClassName('navigation__overlay')[0]

    };

    return {
        init: function() {
            navigation.events();
        },

        events: function() {
            DOM.navToggle.addEventListener('click', function () {
                navigation.toggleNavigation();
            });
            DOM.navOverlay.addEventListener('click', function () {
                navigation.toggleNavigation();
            });
        },

        toggleNavigation: function () {
            DOM.nav.classList.toggle('active');
            DOM.navOverlay.classList.toggle('active');
            DOM.html.classList.toggle('blocked');
        }
    };

})();

document.addEventListener('DOMContentLoaded', navigation.init);