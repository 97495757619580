var tables = (function () {

    // определяем размер вьюпорта
    var _tables = [];
    var _media = "(max-width: 1010px)";

    return {

        /**
         * Работаем с таблицами только на мобильных вьюпортах
         */
        init: function () {
            if ( window.matchMedia( _media ).matches ) {
                tables.findTables( tables.stackTables );
            }
        },

        /**
         * Получаем полный список таблиц, выходящих за пределы вьюпорта и добавляем обёртку всем таблицам
         *
         * @param callback
         */
        findTables: function (callback) {
            var elements = document.getElementsByTagName('table');
            var tables = Array.prototype.slice.call(elements);
            var contentWidth = document.getElementById('wrapper').offsetWidth;

            tables.map(function (table) {
                // если контент выпадает из вьюпорта - отправляем в список для stackTables
                if (table.offsetWidth > contentWidth || table.getElementById('vk_groups').length > 0 || table.hasClass('table-stack')) {

                    console.log(table);
                    _tables.push(table);
                }

                // добавляем div обёртку для всех таблиц
                var wrapperDiv = document.createElement('div');
                wrapperDiv.className = 'table-responsive';
                table.parentNode.insertBefore( wrapperDiv, table );
                wrapperDiv.appendChild(table);
            });

            if ( typeof callback === 'function' ) { callback(); }
        },

        /**
         * Добавляем отдельные ряды для всех ячеек
         */
        stackTables: function () {
            if (_tables.length > 0) {
                _tables.map( function (table) {
                    var elements = table.getElementsByTagName('td');
                    var rowElements = table.getElementsByTagName('tr');
                    var cells = Array.prototype.slice.call(elements);
                    var rows = Array.prototype.slice.call(rowElements);

                    cells.map( function (cell) {
                        var wrapperRow = document.createElement('tr');
                        cell.parentNode.insertBefore( wrapperRow, cell );
                        wrapperRow.appendChild(cell);
                    });

                    rows.map( function (row) {
                        row.outerHTML = row.innerHTML;
                    });
                });
            }
        }

    };

})();

window.onload = tables.init;